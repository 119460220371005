










































import { defineComponent, ref } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'
import axios from 'axios'
import { extend } from 'vee-validate'
import { email, required } from 'vee-validate/dist/rules'

import Message from '@/components/ui/Message.vue'
import * as process from '@/composables/process'
import { getApiUrl } from '@/inc/app.config'
import { getCallbackUrl } from '@/inc/utils'

extend('required', required)
extend('email', email)

export default defineComponent({
  name: 'my-profile-change-email',
  components: {
    Message,
  },
  setup(props, ctx) {
    const { $route } = ctx.root
    const [isProcessing, feedback] = process.init()
    const { email: currentEmail } = useState('user', ['email'])
    const callback = getCallbackUrl('/profile/change-email/')
    const { Token: token } = $route.query
    const hasToken = ref(token !== undefined)
    const newEmail = ref('ChangedEmail@yopmail.com')
    const myState = {
      ...useState('my', ['i18n', 'smartData']),
    }

    const onSubmit = () => {
      const request = axios.post(
        `${getApiUrl()}/users/update/mail/sendmail?Callback=${callback}&Email=${
          newEmail.value
        }`
      )

      process.execute(isProcessing, feedback, request)
    }

    const onConfirm = () => {
      const request = axios.post(
        `${getApiUrl()}/users/update/mail?Token=${token}`
      )

      process.execute(isProcessing, feedback, request, {
        label: 'ChangeEmailError',
      })
    }

    return {
      hasToken,
      currentEmail,
      newEmail,
      feedback,
      onConfirm,
      onSubmit,
      i18n: myState.i18n,
    }
  },
})
