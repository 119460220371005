
















































































import { defineComponent, ref, computed } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'

import { getApiUrl, passwordRegex } from '@/inc/app.config'
import userAccountRequest from '@/composables/userAccountRequest'

import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import VInput from '@/components/form/VInput.vue'
import Message from '@/components/ui/Message.vue'

export default defineComponent({
  name: 'my-profile-change-password',
  components: {
    FeedbackMessage,
    VInput,
    Message,
  },
  setup() {
    const hasValue = ref(false)
    const isLoading = ref(false)
    const isPasswordMatching = ref(false)
    const isPasswordValid = ref(false)
    const currentPassword = ref('')
    const password = ref('')
    const passwordConfirmation = ref('')
    const passwordLength = 10
    const hasError = ref(false)
    const message = ref('')
    const form = ref<HTMLFormElement>()

    const onSubmit = async () => {
      const data = {
        Password: password.value,
        OldPassword: currentPassword.value,
      }

      isLoading.value = true
      hasError.value = false
      message.value = ''

      const { res, error } = await userAccountRequest(
        'users/update/password',
        'post',
        data
      )

      if (error) {
        message.value = error?.response?.data?.message
        hasError.value = true
      } else {
        message.value = res?.data?.message
      }

      isLoading.value = false
    }

    const isReady = computed(
      () => isPasswordValid.value && isPasswordMatching.value
    )

    const checkPassword = () => {
      hasValue.value = true
      isPasswordValid.value =
        passwordRegex.test(password.value) &&
        password.value.length >= passwordLength
    }

    const checkMatch = () => {
      isPasswordMatching.value = password.value === passwordConfirmation.value
    }

    return {
      ...useState('my', ['i18n']),
      checkMatch,
      checkPassword,
      currentPassword,
      message,
      hasValue,
      hasError,
      isLoading,
      isPasswordMatching,
      isPasswordValid,
      isReady,
      onSubmit,
      password,
      passwordConfirmation,
      passwordLength,
    }
  },
})
