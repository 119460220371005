




































import { getApiUrl } from '@/inc/app.config'
import { useGetters, useState } from '@u3u/vue-hooks'
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import Axios from 'axios'

export default defineComponent({
  name: 'my-profile-delete-request',
  components: {},

  setup(_props) {
    const { user } = useState(['user'])
    const { currentLang } = useGetters(['currentLang'])
    const { i18n } = useState('my', ['i18n'])
    const replaceVariables = string =>
      string.replace(
        '%mail%',
        `<span class="bold">${
          user.value.contact.contactEmail || user.value.email
        }</span>`
      )
    const isLoading = ref(true)
    const error = ref(false)

    onMounted(async () => {
      await Axios.post(
        `${getApiUrl()}/users/delete/sendmail?Callback=${
          window.location.origin
        }/${currentLang.value}/profile/delete-confirmation`
      )
        .then(() => {
          isLoading.value = false
        })
        .catch(() => {
          error.value = true
        })
    })

    return {
      i18n,
      replaceVariables,
      isLoading,
      error,
      user,
    }
  },
})
