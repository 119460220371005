



















































































































































import { useActions, useGetters, useMutations, useState } from '@u3u/vue-hooks'
import { defineComponent, ref, Ref } from '@vue/composition-api'
import { filter, keys } from 'rambda'

import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import VCheckbox from '@/components/form/VCheckbox.vue'
import VRadio from '@/components/form/VRadio.vue'

import userAccountRequest from '@/composables/userAccountRequest'
import { keysToSnake } from '@/inc/utils'
import { MyState } from '@/inc/types'
import TipPanel from '@/components/TipPanel.vue'
import CustomLink from '@/components/g/CustomLink.vue'

// import { snakeCase } from 'snake-case'

export default defineComponent({
  name: 'my-preferences',
  components: { FeedbackMessage, VCheckbox, VRadio, CustomLink },
  props: {},

  setup(props, ctx) {
    const { user, chrome } = useState(['user', 'chrome'])
    const { i18n } = useState('my', ['i18n']) as { i18n: Ref<MyState['i18n']> }
    const { UPDATE_USER } = useMutations('user', ['UPDATE_USER'])
    const { $logger } = ctx.root
    const { currentLang } = useGetters(['currentLang'])

    const suffixes = ['Mail', 'Postal', 'Sms']
    const preferences = ref(user.value.preferences)

    const { showPanel, resetPanel } = useActions('sidePanel', [
      'showPanel',
      'resetPanel',
    ])

    const isLegacy =
      Object.keys(preferences.value).length === 0 ||
      (Object.keys(preferences.value).length === 1 && preferences.value.com)

    if (!preferences.value.comMarketingMail) {
      preferences.value.comMarketingMail = false
    }

    const filteredOptions = (prefix: string) =>
      filter(
        (_val, prop) => prop.startsWith(prefix),
        preferences.value as Record<string, boolean>
      )

    const formattedProps = (options: Record<string, boolean>) =>
      suffixes.map(
        suffix =>
          Object.keys(options).find(prop => prop.endsWith(suffix)) || null
      )

    const selectedProps = (options: Record<string, boolean>) =>
      keys(filter(val => val, options)) as string[]

    /* const passOptions = filteredOptions('comPass')
    const passProps = formattedProps(passOptions)
    const passModel = ref(selectedProps(passOptions)[0] || '') */

    const racOptions = filteredOptions('comDossier')
    const racProps = formattedProps(racOptions)
    const racModel = ref(selectedProps(racOptions)[0] || '')

    const encodOptions = filteredOptions('comEncod')
    const encodProps = formattedProps(encodOptions)
    const encodModel = ref(selectedProps(encodOptions)[0] || '')

    const pppOptions = filteredOptions('comPpp')
    const pppProps = formattedProps(pppOptions)
    const pppModel = ref(selectedProps(pppOptions))

    const feedbackMessage = ref('')
    const hasError = ref(false)
    const isLoading = ref(false)

    const updatePrefs = (
      options: Record<string, boolean>,
      model: Ref<string | string[]>
    ) => {
      const selection = Array.isArray(model.value) ? model.value : [model.value]

      Object.keys(options).forEach(k => {
        preferences.value[k] = false

        if (selection.includes(k)) {
          preferences.value[k] = true
        }
      })
    }

    const onSubmit = async () => {
      hasError.value = false
      isLoading.value = true
      feedbackMessage.value = ''

      // updatePrefs(passOptions, passModel)
      updatePrefs(racOptions, racModel)
      updatePrefs(encodOptions, encodModel)
      updatePrefs(pppOptions, pppModel)

      const { error } = await userAccountRequest(
        'me/preferences',
        'post',
        keysToSnake(preferences.value)
      )

      if (error) {
        $logger.error('GET OPTIONS', error.response)
        isLoading.value = false
        hasError.value = true
        feedbackMessage.value = i18n.value.preferences.error

        return
      }

      UPDATE_USER({ preferences: preferences.value })

      isLoading.value = false
      feedbackMessage.value = i18n.value.preferences.confirm
    }

    // LEGACY: reset old and wrong prefs
    // preferences.Com.com_conf_paiement_mail
    /* eslint-disable camelcase, id-length */
    const prefsDefault = {
      com_conf_paiement_mail: false,
      com_conf_paiement_postal: false,
      com_conf_paiement_sms: false,
      com_conf_sepa_mail: false,
      com_conf_sepa_postal: false,
      com_conf_sepa_sms: false,
      com_dossier_racc_mail: false,
      com_dossier_racc_postal: false,
      com_dossier_racc_sms: false,
      com_encod_index_mail: false,
      com_encod_index_postal: false,
      com_encod_index_sms: false,
      com_global: 'mail',
      com_marketing_mail: false,
      com_panne_evo_mail: false,
      com_panne_evo_sms: false,
      com_panne_fin_mail: false,
      com_panne_fin_sms: false,
      com_panne_mail: false,
      com_panne_sms: false,
      com_pass_index_mail: false,
      com_pass_index_postal: false,
      com_pass_index_sms: false,
      com_ppp_mail: false,
      com_ppp_sms: false,
      com_rappel_paiement_mail: false,
      com_rappel_paiement_postal: false,
      com_rappel_paiement_sms: false,
    }
    /* eslint-enable camelcase, id-length */
    const reset = async () => {
      try {
        await userAccountRequest('me/preferences/Com', 'delete')
      } catch (error) {
        console.error('Delete legacy prefs error', error)
      }

      try {
        await userAccountRequest('me/preferences', 'post', prefsDefault)
      } catch (error) {
        console.error('Set default prefs error', error)
      }
    }

    // Methode pour afficher le panel
    const showTipPanel = () => {
      resetPanel()
      showPanel({
        component: {
          template: TipPanel,
        },
        content: {
          title: i18n.value.preferences.tipTitle,
          htmltext: i18n.value.preferences.tipText,
        },
      })
    }

    return {
      chromeI18n: chrome.value.data.i18n,
      isLegacy,
      preferences,
      suffixes,
      reset,
      racProps,
      racModel,
      encodProps,
      encodModel,
      pppProps,
      pppModel,
      onSubmit,
      feedbackMessage,
      hasError,
      i18n,
      isLoading,
      user,
      showTipPanel,
      currentLang,
    }
  },
})
