







import { defineComponent, ref } from '@vue/composition-api'
import MyProfileChangeEmail from '@/components/my/profile/ChangeEmail.vue'
import MyProfileChangePassword from '@/components/my/profile/ChangePassword.vue'
import MyProfileView from '@/components/my/profile/View.vue'
import MyProfileDeleteRequest from '@/components/my/profile/DeleteRequest.vue'
// eslint-disable-next-line id-length
import MyProfileDeleteConfirmation from '@/components/my/profile/DeleteConfirmation.vue'
import MyProfilePreferences from '@/components/my/profile/Preferences.vue'
import Notifications from '@/components/Notifications.vue'
import { useGetters } from '@u3u/vue-hooks'

/* eslint-disable quote-props */
const subs = {
  'change-email': MyProfileChangeEmail,
  'change-password': MyProfileChangePassword,
  notifications: Notifications,
  preferences: MyProfilePreferences,
  view: MyProfileView,
  'delete-request': MyProfileDeleteRequest,
  'delete-confirmation': MyProfileDeleteConfirmation,
}

export default defineComponent({
  name: 'my-profile',
  setup(props, ctx) {
    const { $logger, $route } = ctx.root
    const isLoading = ref(false)

    return {
      componentId: subs[$route.params.sub] || subs.view,
      isLoading,
      ...useGetters('user', ['isFetching']),
    }
  },
})
