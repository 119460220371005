/* eslint-disable @typescript-eslint/no-explicit-any */
const { NODE_ENV } = process.env
const isDev = NODE_ENV === 'development'

import { ref, Ref } from '@vue/composition-api'
import { AxiosResponse } from 'axios'
import logger from 'consola'

import { Feedback } from '@/inc/types'

type StatusRef = Ref<boolean>
type FeedbackRef = Ref<null | Feedback>
interface MessageData {
  message?: string
  label?: string
}

const formatMessage = (msgDefault: string, data: MessageData | undefined) => {
  let msg: string = data?.message || msgDefault

  if (!/^<.+>$/.test(msg)) {
    msg = `<div>${msg}</div>`
  }

  return msg
}

export const init = (): [StatusRef, FeedbackRef] => [
  ref(false),
  ref<null | Feedback>(null),
]
export const execute = async (
  status: StatusRef,
  feedback: FeedbackRef,
  request: Promise<AxiosResponse<any>>,
  data?: MessageData
) => {
  status.value = true

  try {
    const res = await request

    const { status } = res
    const { code, message } = res.data

    if (status === 200) {
      success(feedback, data)
    } else {
      warning(feedback, { ...data, message: `${message} [${code}]` })
    }
  } catch (err) {
    error(feedback, err, data)
  }

  status.value = false
}

export const success = (feedback: FeedbackRef, data?: MessageData) => {
  feedback.value = {
    severity: 'success',
    label: data?.label || 'Succès',
    htmltext: formatMessage('<p>Success</p>', data),
  }
}

export const warning = (feedback: FeedbackRef, data?: MessageData) => {
  feedback.value = {
    severity: 'warning',
    label: data?.label || 'Avertissement',
    htmltext: formatMessage('<p>Il y a eu un problème</p>', data),
  }
}

export const error = (
  feedback: FeedbackRef,
  error: Error,
  data?: MessageData
) => {
  logger.error(error)
  feedback.value = {
    severity: 'error',
    label: data?.label || 'Erreur',
    htmltext: isDev
      ? `<pre>${error.stack}]</pre>`
      : `<p>Une erreur est survenue : ${error.message}]</p>`,
  }
}
