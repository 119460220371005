






































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from '@vue/composition-api'
import { useState, useMutations, useActions, useGetters } from '@u3u/vue-hooks'
import userAccountRequest from '@/composables/userAccountRequest'

import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import UserForm from '@/components/my/UserForm.vue'
import VInput from '@/components/form/VInput.vue'
import auth from '@/composables/auth'
import CustomLink from '@/components/g/CustomLink.vue'

export default defineComponent({
  name: 'my-profile-view',
  components: {
    FeedbackMessage,
    UserForm,
    VInput,
    CustomLink,
  },

  setup(_props, ctx) {
    const isValid = ref(false)
    const isLoading = ref(false)
    const hasError = ref(false)
    const message = ref('')
    const form = ref<HTMLFormElement>()
    const { $logger, $route, $router } = ctx.root
    const { user, chrome } = useState(['user', 'chrome'])
    const { i18n } = useState('my', ['i18n'])
    const { UPDATE_USER } = useMutations('user', ['UPDATE_USER'])
    const phoneCode = ref()
    const phoneCodeChange = ref()
    const { refreshUser } = useActions('user', ['refreshUser'])
    const { currentLang } = useGetters(['currentLang'])
    const inputsValues = reactive({
      Firstname: user.value.firstname,
      Lastname: user.value.lastname,
      Phone: user.value.contact.phone,
      PhoneFixe: user.value.contact.phoneFixe,
      ContactEmail: user.value.contact.contactEmail,
      Email: user.value.email,
      Adresse: {
        Cdpostal: user.value.contact.adresse.cdpostal,
        Localite: user.value.contact.adresse.localite,
        Rue: user.value.contact.adresse.rue,
        NumRue: user.value.contact.adresse.numRue,
        CodePays: 'BE',
      },
    })
    // eslint-disable-next-line id-length
    const showModalValidContactEmail = ref(false)

    onMounted(() => {
      onInput()
    })

    watch(currentLang, old => {
      refreshUser()
    })

    const phoneError = ref(false)
    ctx.root.$on('error:phone', (value: boolean) => handleErrorPhone(value))
    const handleErrorPhone = value => {
      phoneError.value = value
    }
    const onInput = () => {
      isValid.value = !phoneError.value
    }

    const landlineError = ref(false)
    ctx.root.$on('error:landline', (value: boolean) =>
      handleLandlineError(value)
    )
    const handleLandlineError = value => {
      landlineError.value = value
    }

    if (
      user.value.contact.phone.match(
        // eslint-disable-next-line max-len
        /(?:\+|^00)(1|7|2[07]|3[0123469]|4[013456789]|5[12345678]|6[0123456]|8[1246]|9[0123458]|(?:2[12345689]|3[578]|42|5[09]|6[789]|8[035789]|9[679])\d)/,
        ''
      )
    ) {
      ;[phoneCode.value] = user.value.contact.phone.match(
        // eslint-disable-next-line max-len
        /(?:\+|^00)(1|7|2[07]|3[0123469]|4[013456789]|5[12345678]|6[0123456]|8[1246]|9[0123458]|(?:2[12345689]|3[578]|42|5[09]|6[789]|8[035789]|9[679])\d)/,
        ''
      )
    }

    ctx.root.$on(
      'phone:flag:updated:myUserForm',
      (emitted: { phoneCode: string; placeHolder: string; regex: string }) => {
        phoneCodeChange.value = emitted.phoneCode
      }
    )

    ctx.root.$on('updateContactMail', (val: boolean) => updateContactMail(val))

    // eslint-disable-next-line id-length
    const updateContactMail = val => {
      console.log('emmit', val)
      // eslint-disable-next-line id-length
      showModalValidContactEmail.value = val
    }
    /* eslint-disable */
    const disabledCondition = computed(() => {
      if (
          (user.value.firstname !== inputsValues.Firstname ||
              user.value.lastname !== inputsValues.Lastname ||
              user.value.contact.phone !== inputsValues.Phone ||
              phoneCode.value !== phoneCodeChange.value ||
              user.value.contact.phoneFixe !== inputsValues.PhoneFixe ||
              user.value.contact.contactEmail !== inputsValues.ContactEmail ||
              user.value.contact.adresse.cdpostal !==
              inputsValues.Adresse.Cdpostal ||
              user.value.contact.adresse.localite !==
              inputsValues.Adresse.Localite ||
              user.value.contact.adresse.rue !== inputsValues.Adresse.Rue ||
              user.value.contact.adresse.numRue !== inputsValues.Adresse.NumRue) &&
          (!phoneError.value && inputsValues.Phone && Object.values(inputsValues.Adresse).every(val => val))
      ) {
        if (user.value.contact.phoneFixe !== inputsValues.PhoneFixe && landlineError.value) {
          isValid.value = false
          return true
        }

        isValid.value = true
        return false
      }

      return true
    })

    const onSubmit = async () => {
      form.value!.reportValidity()
      console.log(user)
      $logger.debug('USER UPDATE')
      if (!user.value.validContactEmail || user.value.contact.contactEmail !== inputsValues.ContactEmail) {
        updateContactMail(true)
      }
      message.value = ''

      if (!isValid.value) {
        return
      }

      isLoading.value = true
      hasError.value = false

      const {error} = await userAccountRequest('users', 'patch', inputsValues)

      if (error) {
        $logger.error('USER UPDATE', error)
        message.value = error?.response?.data?.message
        hasError.value = true
      } else {
        message.value = i18n.value.personnalData?.confirm
        phoneCodeChange.value = phoneCode.value
        const userNewData = {
          contact: {
            phone: inputsValues.Phone,
            phoneFixe: inputsValues.PhoneFixe,
            contactEmail: inputsValues.ContactEmail,
            adresse: {
              cdpostal: inputsValues.Adresse.Cdpostal,
              localite: inputsValues.Adresse.Localite,
              numRue: inputsValues.Adresse.NumRue,
              rue: inputsValues.Adresse.Rue,
            },
          },
          firstname: inputsValues.Firstname,
          lastname: inputsValues.Lastname,
        }
        UPDATE_USER(userNewData)
      }

      isLoading.value = false
    }

    const globalI18n = computed(() => chrome.value.data.i18n)

    return {
      handleErrorPhone,
      updateContactMail,
      form,
      globalI18n,
      hasError,
      i18n,
      inputsValues,
      isLoading,
      isValid,
      message,
      onInput,
      onSubmit,
      disabledCondition,
      phoneError,
      landlineError,
      ...useGetters('user', ['isFetching']),
      showModalValidContactEmail,
    }
  },
})
