
































import { getApiUrl } from '@/inc/app.config'
import { useState } from '@u3u/vue-hooks'
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import Axios from 'axios'
import gtm, { Event } from '@/composables/gtm'

export default defineComponent({
  name: 'my-profile-delete-request',
  components: {},

  setup(_props) {
    const { user } = useState(['user'])
    const { i18n } = useState('my', ['i18n'])
    const accountEmail = new URLSearchParams(window.location.search).get(
      'email'
    )
    const token = new URLSearchParams(window.location.search).get('Token')

    const replaceVariables = string =>
      string &&
      string.replace('%mail%', `<span class="bold">${accountEmail}</span>`)

    const isLoading = ref(true)
    const error = ref(false)

    onMounted(async () => {
      await Axios.get(`${getApiUrl()}/users/delete?Token=${token}`)
        .then(() => {
          const event = {
            event: 'MyRESA_events',
            eventLabel: 'Suppression_myresa_account',
          } as Event
          gtm.sendEvent(event)
          isLoading.value = false
        })
        .catch(() => {
          error.value = true
        })
    })

    return {
      i18n,
      replaceVariables,
      isLoading,
      error,
      user,
    }
  },
})
