var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-profile-change-password"},[_c('h1',{staticClass:"h2 mb-m"},[_vm._v(_vm._s(_vm.i18n.updatePassword))]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('message',{attrs:{"content":{
        label: _vm.i18n.finalise.fields.label,
        htmltext: _vm.i18n.finalise.fields.rule,
      },"severity":"success"}}),_c('v-input',{staticClass:"mb-s",attrs:{"label":_vm.i18n.finalise.fields.currentPassword,"required":true,"type":"password","id":_vm.i18n.finalise.fields.currentPassword},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}}),_c('v-input',{staticClass:"mb-s",attrs:{"label":_vm.i18n.finalise.fields.password,"minlength":_vm.passwordLength,"required":true,"type":"password","id":_vm.i18n.finalise.fields.password},on:{"blur":_vm.checkPassword},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-input',{staticClass:"mb-s",attrs:{"label":_vm.i18n.finalise.fields.passwordConfirm,"minlength":_vm.passwordLength,"required":true,"type":"password","id":_vm.i18n.finalise.fields.passwordConfirm},on:{"input":_vm.checkMatch},model:{value:(_vm.passwordConfirmation),callback:function ($$v) {_vm.passwordConfirmation=$$v},expression:"passwordConfirmation"}}),(_vm.message)?_c('feedback-message',{staticClass:"mb-s",attrs:{"content":{ type: _vm.hasError ? 'error' : 'success', htmltext: _vm.message }}}):_vm._e(),(_vm.hasValue && !_vm.isPasswordValid && _vm.password)?_c('feedback-message',{staticClass:"mb-s",attrs:{"content":{ type: 'error', htmltext: _vm.i18n.finalise.fields.rule }}}):_vm._e(),(!_vm.isPasswordMatching && _vm.isPasswordValid && _vm.passwordConfirmation)?_c('feedback-message',{staticClass:"mb-s",attrs:{"content":{
        type: 'error',
        htmltext: _vm.i18n.finalise.fields.donotmatch,
      }}}):_vm._e(),_c('g-action',{staticClass:"submit",class:{ 'is-loading': _vm.isLoading },attrs:{"content":{
        tag: 'button',
        label: _vm.i18n.save,
        icon: 'arrow',
      },"disabled":!_vm.isReady,"type":"submit"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }